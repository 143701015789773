<template>
  <div class="w-full p-5 text-base-content-300">
    <div class="flex flex-col gap-2">
      <div
        v-if="isLoading" class=" flex min-h-full min-w-full justify-center items-center rounded-md " >
        <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
        Loading
      </div>
      <div v-if="packageData && !isLoading" class="flex flex-col gap-2">
        <span class="text-base-content-300 text-md font-medium"> SLA (in Days) </span>
        <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
          <div class="flex flex-col justify-between min-h-full">
            <div class="form flex w-full flex-col flex-1">
              <FormulateInput
                class="w-full"
                placeholder="SLA in Days"
                type="number"
                validation="required|number|min:0,number"
                v-model="packageData.turn_around_time"
                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
              />
            </div>
            <div class="card-actions justify-end">
              <Button
                text="Update"
                type="primary"
                :disabled="hasErrors"
                @click="updateTat()"
              />
            </div>
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
const Button = () => import("@/components/button");
// import axios from "@/axios";
// import loader from "@/components/loader";
import axios from "@/axios";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "tat",
    components: {
        // loader,
        Button,
    },
    data: () => ({
        packageData: null,
        isLoading: false,
    }),
    async mounted() {
        await this.fetchScreeningData();
    },
    computed: {
        ...mapGetters(["getPackage"]),
    },

    methods: {
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
        this.isLoading = true;
        if (!this.getPackage) {
            await this.fetchPackageData(this.$route.params.packageName);
            this.packageData = this.getPackage;
        }
        this.packageData = this.getPackage;
        this.isLoading = false;
        },
        async updateTat() {
            const url = `/screening-package/${this.packageData.id}`;
            const payload = {
                turn_around_time: this.packageData.turn_around_time,
            };
            try {
                await axios.put(url, payload);
                this.SET_PACKAGE = {...this.getPackage, turn_around_time:this.packageData.turn_around_time}
                this.$toast.success("Package turn around time updated.");
            } catch (error) {
                this.$toast.warning("Could not update package turn around time.");
            }
        },
    },
};
</script>

<style scoped>
</style>